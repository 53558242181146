body {
        font-size: 0.825rem;
}
.sidebar .nav{
    min-height: auto;
}
.app-login {
    min-height: 70vh;
    flex-direction: column;
    height: 80vh;
    display: flex;

}




.invalid-feedback{
    display:block;
}

.input-group-text {
    width: 45px;
    text-align: center;
    display: block;
}

.content-wrap {
    padding: 60px;
}


.content-wrap p{
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 16px;
    margin: 51px 0;
    text-align: justify;
    letter-spacing: justify;
}
.app-body {
    overflow:  hidden;
}

.adminlogin .logo {
    display: block;
    margin: 0 auto;
    margin-bottom: 35px;
    height: 50px;
}

.custom-sub-header {
    /* background: #fff; */
    position: fixed;
    z-index: 1019;
    margin-top: 55px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-top: 0px;
    padding: 0px 15px;
    border-bottom: 1px solid #c8ced3;
    /* border-top: 1px solid #c8ced3; */
    display: none;
    background-color: #f2f2f2;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; 
}
.custom-sub-header-two {
    margin-top: 95px;
}
    

.header-fixed.sidebar-fixed .custom-sub-header  {
    display : none;
}

.header-fixed.sidebar-fixed .app-body {
    margin-top : 55px
}

 .header-fixed.sidebar-fixed .app-header {
    border-bottom :  1px solid #f1f1f1;
}


@media (min-width: 481px) and (max-width: 767px) {
    .main .container-fluid {
        padding : 0 15px;
    }
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    
    .main .container-fluid {
        padding : 0 15px;
    }
    
  }



  .custom-file-label  {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.btn-group-xs > .btn, .btn-xs {
    padding  : 0.375rem 0.75rem;
    font-size  : .575rem;
    line-height  : 1;
    border-radius : 0rem;
  }

  .flag-icon {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 1.33333333em;
    line-height: 1em;
}
.flag-icon:before {
    content: "\A0";
}
.flag-icon-us {
    background-image: url('../assets/img/us.svg');
}


.flag-icon-in {
    background-image: url('../assets/img/in.svg');
}


.DateInput_input__focused {
    border-bottom: 2px solid transparent;
}

.input-group .form-control-select {
    border:1px solid #e4e7ea

}
.input-group .SingleDatePicker, .input-group .form-control-select {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}

.input-group > .form-control-select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.form-control-select{
    outline: 0 !important;
    /* border: 0px!important;     */
}
.form-control-select > div{
    border: 0px!important;    
    outline: 0!important;
}

/* .form-control-select > div{
    color: #5c6873;
    background-color: #fff;
    border-color: #4acffe;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(1, 148, 200, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(1, 148, 200, 0.25);
} */

.input-group-text-date {
    padding: 0.345rem 0.75rem;
}

.SingleDatePickerInput__withBorder{
    border: 1px solid #e4e7ea;
    border-radius: 0.25rem;
}

.DateInput_input {
    display: block;
    width: 100%;
    height: calc(2.0625rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight:400;
    line-height: 1.5;
    color: #5c6873;
    background-color: #fff;
    background-clip: padding-box;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.react-bootstrap-table table{
    table-layout: auto;
}

.react-bootstrap-table {
    overflow-x: initial;
}

.vertical-timeline p, .vertical-timeline-element-date {
    margin : 0;
    padding :0;
}
.vertical-timeline-element-content .vertical-timeline-element-date{
    padding: 0;
}

.vertical-timeline-element-content {
    position: relative;
    margin-left: 48px;
    background: #fff;
    border-radius: .25em;
    padding: 0.6em;
    -webkit-box-shadow: 0 3px 0 #ddd;
    box-shadow: 0 3px 0 #ddd;
}

.vertical-timeline-element-icon {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    -webkit-box-shadow: initial;
    box-shadow: initial;
    text-align: center;
    font-size:12px;
}

.vertical-timeline-element-content::before {
    content: '';
    position: absolute;
    top: 12px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #fff;
}

.vertical-timeline-element {
    margin: 0.5em 0 !important;
}

.vertical-timeline {
    width: 95%;
    max-width: 1370px;
    margin: 0.5em auto;
    position: relative;
    padding: 0.5em 0;
}

.vertical-timeline-element-content .vertical-timeline-element-date, .vertical-timeline-element-content p {
    font-size: 13px;
    font-size: .8125rem;  
}

.dropdown-notification .dropdown-item {
    max-width:350px;
    white-space : initial;
}

.notification-readall{
    height: 20px;
    width: 20px;
    text-align: center;
    font-size: 8px;
    padding: 0;
    line-height: 20px;
    float:right;
}

.modal-lg {
    max-width: 80% !important;
}

.unread-notification-class{
  background-color: #e3f8ff
}

.transaction-notification-class {
    background-color: #fff2d6 
}

.dropdown-item .fa-stack-1x {
        font-size: 0.6em;
}

.dropdown-item .fa-stack-2x {
        font-size: 1em;
        height: 1em;
}

.dropdown-item .fa-stack {
        height: 1em;
        width: 1em;
        font-size: 1.2em;
        line-height: 1em;
}
.helpLink {
    border-bottom: 1px dashed #2275B4;
}
.helpLink:hover {
    border-bottom: 1px solid #2275B4;
    cursor: pointer !important;
}

.custom-file-input, .custom-file-label{
   z-index: 0; 
}

@media (max-width:  991.98px)  {
    .app-header .navbar-brand {
        position: absolute;
        left: 2.2em;
        margin-left: 0;
    }
}

.tooltip-inner {
    max-width: 250px;
}

.inline-form .form-control-select > div{
    border: 1px solid #ccc !important;
}
.no-data-warning {
    width :100%;
    padding: 10%;
}
.no-data-warning h3 {
    margin : 20px  0;
} 

.table-sm .dropdown>button.btn-link {
    padding:0
}

table .collapse-td > td {
    padding:0;
}

 table .collapse-td> td >.collapse,  table .collapse-td> td >.collapsing {
    padding: 0.3rem;
}
.cursor-pointer {
    cursor: pointer;
}

.react-checkbox-tree {
    font-size: 14px;
}

.swal-footer {
    text-align: center;
  }

 .ql-container .ql-editor{
    min-height: 200px !important;
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: scroll;
  }


.mendetoryFields { 
    color: "red";
     font-size: 14px;
      margin-left: "2px";
     }

     .ajax_loading {
        position:fixed;
        display: none;
        top:0px;
        right:0px;
        width:100%;
        height:100%;
        background-color:rgba(101, 101, 101, 0.3);
        z-index:100000000;}
    
    .sk-circle {
      margin: auto;
      width: 40px;
      height: 40px;
      position: relative; 
    top:40%;}
      .sk-circle .sk-child {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0; }
      .sk-circle .sk-child:before {
        content: '';
        display: block;
        margin: 0 auto;
        width: 15%;
        height: 15%;
        background-color: #137EFF;
        border-radius: 100%;
        -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
                animation: sk-circleBounceDelay 1.2s infinite ease-in-out both; }
      .sk-circle .sk-circle2 {
        -webkit-transform: rotate(30deg);
            -ms-transform: rotate(30deg);
                transform: rotate(30deg); }
      .sk-circle .sk-circle3 {
        -webkit-transform: rotate(60deg);
            -ms-transform: rotate(60deg);
                transform: rotate(60deg); }
      .sk-circle .sk-circle4 {
        -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
                transform: rotate(90deg); }
      .sk-circle .sk-circle5 {
        -webkit-transform: rotate(120deg);
            -ms-transform: rotate(120deg);
                transform: rotate(120deg); }
      .sk-circle .sk-circle6 {
        -webkit-transform: rotate(150deg);
            -ms-transform: rotate(150deg);
                transform: rotate(150deg); }
      .sk-circle .sk-circle7 {
        -webkit-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
                transform: rotate(180deg); }
      .sk-circle .sk-circle8 {
        -webkit-transform: rotate(210deg);
            -ms-transform: rotate(210deg);
                transform: rotate(210deg); }
      .sk-circle .sk-circle9 {
        -webkit-transform: rotate(240deg);
            -ms-transform: rotate(240deg);
                transform: rotate(240deg); }
      .sk-circle .sk-circle10 {
        -webkit-transform: rotate(270deg);
            -ms-transform: rotate(270deg);
                transform: rotate(270deg); }
      .sk-circle .sk-circle11 {
        -webkit-transform: rotate(300deg);
            -ms-transform: rotate(300deg);
                transform: rotate(300deg); }
      .sk-circle .sk-circle12 {
        -webkit-transform: rotate(330deg);
            -ms-transform: rotate(330deg);
                transform: rotate(330deg); }
      .sk-circle .sk-circle2:before {
        -webkit-animation-delay: -1.1s;
                animation-delay: -1.1s; }
      .sk-circle .sk-circle3:before {
        -webkit-animation-delay: -1s;
                animation-delay: -1s; }
      .sk-circle .sk-circle4:before {
        -webkit-animation-delay: -0.9s;
                animation-delay: -0.9s; }
      .sk-circle .sk-circle5:before {
        -webkit-animation-delay: -0.8s;
                animation-delay: -0.8s; }
      .sk-circle .sk-circle6:before {
        -webkit-animation-delay: -0.7s;
                animation-delay: -0.7s; }
      .sk-circle .sk-circle7:before {
        -webkit-animation-delay: -0.6s;
                animation-delay: -0.6s; }
      .sk-circle .sk-circle8:before {
        -webkit-animation-delay: -0.5s;
                animation-delay: -0.5s; }
      .sk-circle .sk-circle9:before {
        -webkit-animation-delay: -0.4s;
                animation-delay: -0.4s; }
      .sk-circle .sk-circle10:before {
        -webkit-animation-delay: -0.3s;
                animation-delay: -0.3s; }
      .sk-circle .sk-circle11:before {
        -webkit-animation-delay: -0.2s;
                animation-delay: -0.2s; }
      .sk-circle .sk-circle12:before {
        -webkit-animation-delay: -0.1s;
                animation-delay: -0.1s; }
    
    @-webkit-keyframes sk-circleBounceDelay {
      0%, 80%, 100% {
        -webkit-transform: scale(0);
                transform: scale(0); }
      40% {
        -webkit-transform: scale(1);
                transform: scale(1); } }
    
    @keyframes sk-circleBounceDelay {
      0%, 80%, 100% {
        -webkit-transform: scale(0);
                transform: scale(0); }
      40% {
        -webkit-transform: scale(1);
                transform: scale(1); } }
    
                .react-datepicker__time-container {
    width:100px;

                }
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width:auto;
}
.homesearchselectpicker {
    max-width: 55px;
    padding: 0 6px;
}

.toast-body p {
    margin-bottom: 0px
}

.tickets-messages .toast-body {
    padding: 0;
}
.tickets-messages .ql-editor {
    min-height: initial !important;
    max-height: initial!important;
    overflow: initial!important;
    overflow-y: initial!important;
    overflow-x: initial!important;
}

/* .react-bootstrap-table {
    overflow-x: auto;
} */
.ck-editor__editable_inline {
    min-height: 400px;
}

.settings .list-group-item {
    padding: 3px 10px
}

.linebreaks {
    white-space:pre;            
    }

    .notification-round-button {
        border-radius : 50px;
        padding : 5px 10px;
    }
    @media ( max-width: 767px) {
        .header-fixed.sidebar-fixed .app-body {
            margin-top: 50px;
        }
        
    .notification-round-button {
        border-radius : 30px;
        padding : 3px 5px;
        font-size: 10px !important;
    }
    .notification-round-button span {
        font-size: 10px !important;
    }
    
    }

  
  
  
  
  /* Chat */
  
  .chat {
    height: calc(100% - 69px);
  }
  
  .chat-container {
    height: 100%;
  }
  
  
  /* Conversation */
  
  .conversation {
    height: calc(100% - 12px);
    position: relative;
    background: #efe7dd url("https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg") repeat;
    z-index: 0;
  }
  
  .conversation ::-webkit-scrollbar {
    transition: all .5s;
    width: 5px;
    height: 1px;
    z-index: 10;
  }
  
  .conversation ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .conversation ::-webkit-scrollbar-thumb {
    background: #b3ada7;
  }
  
  .conversation .conversation-container {
    height: calc(100% - 68px);
    box-shadow: inset 0 10px 10px -10px #000000;
    overflow-x: hidden;
    padding: 0 16px;
    margin-bottom: 5px;
  }
  
  .conversation .conversation-container:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Messages */
  
  .message {
    color: #000;
    clear: both;
    line-height: 18px;
    font-size: 15px;
    padding: 8px;
    position: relative;
    margin: 8px 0;
    max-width: 85%;
    word-wrap: break-word;
    /* z-index: -1; */
  }
  
  .message:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
  }
  
  .metadata {
    display: inline-block;
    float: right;
    padding: 0 0 0 7px;
    position: relative;
    bottom: -4px;
  }
  
  .metadata .time {
    color: rgba(0, 0, 0, .45);
    font-size: 11px;
    display: inline-block;
  }
  
  .metadata .tick {
    display: inline-block;
    margin-left: 2px;
    position: relative;
    top: 4px;
    height: 16px;
    width: 16px;
  }
  
  .metadata .tick svg {
    position: absolute;
    transition: .5s ease-in-out;
  }
  
  .metadata .tick svg:first-child {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform: perspective(800px) rotateY(180deg);
            transform: perspective(800px) rotateY(180deg);
  }
  
  .metadata .tick svg:last-child {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform: perspective(800px) rotateY(0deg);
            transform: perspective(800px) rotateY(0deg);
  }
  
  .metadata .tick-animation svg:first-child {
    -webkit-transform: perspective(800px) rotateY(0);
            transform: perspective(800px) rotateY(0);
  }
  
  .metadata .tick-animation svg:last-child {
    -webkit-transform: perspective(800px) rotateY(-179.9deg);
            transform: perspective(800px) rotateY(-179.9deg);
  }
  
  .message:first-child {
    margin: 16px 0 8px;
  }
  
  .message.received {
    background: #fff;
    border-radius: 0px 5px 5px 5px;
    float: left;
  }
  
  .message.received .metadata {
    padding: 0 0 0 16px;
  }
  
  .message.received:after {
    border-width: 0px 10px 10px 0;
    border-color: transparent #fff transparent transparent;
    top: 0;
    left: -10px;
  }
  
  .message.sent {
    background: #e1ffc7;
    border-radius: 5px 0px 5px 5px;
    float: right;
  }
  
  .message.sent:after {
    border-width: 0px 0 10px 10px;
    border-color: transparent transparent transparent #e1ffc7;
    top: 0;
    right: -10px;
  }
  
  /* Compose */
  
  .conversation-compose {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    overflow: hidden;
    height: 50px;
    width: 100%;
    z-index: 2;
  }
  
  .conversation-compose div,
  .conversation-compose input {
    background: #fff;
    height: 100%;
  }
  
  .conversation-compose .emoji {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 5px 0 0 5px;
    flex: 0 0 auto;
    margin-left: 8px;
    width: 48px;
  }
  
  .conversation-compose .input-msg {
    border: 0;
    flex: 1 1 auto;
    font-size: 16px;
    margin: 0;
    outline: none;
    min-width: 50px;
  }
  
  .conversation-compose .photo {
    flex: 0 0 auto;
    border-radius: 0 0 5px 0;
    text-align: center;
    position: relative;
    width: 48px;
  }
  
  .conversation-compose .photo:after {
    border-width: 0px 0 10px 10px;
    border-color: transparent transparent transparent #fff;
    border-style: solid;
    position: absolute;
    width: 0;
    height: 0;
    content: "";
    top: 0;
    right: -10px;
  }
  
  .conversation-compose .photo i {
    display: block;
    color: #7d8488;
    font-size: 24px;
    transform: translate(-50%, -50%);
    position: relative;
    top: 50%;
    left: 50%;
  }
  
  .conversation-compose .send {
    background: transparent;
    border: 0;
    cursor: pointer;
    flex: 0 0 auto;
    margin-left: 8px;
    margin-right: 8px;
    padding: 0;
    position: relative;
    outline: none;
  }
  
  .conversation-compose .send .circle {
    background: #008a7c;
    border-radius: 50%;
    color: #fff;
    position: relative;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .conversation-compose .send .circle i {
    font-size: 24px;
    margin-left: 5px;
  }
  
  /* Small Screens */
  
  @media (max-width: 768px) {
    .marvel-device.nexus5 {
      border-radius: 0;
      flex: none;
      padding: 0;
      max-width: none;
      overflow: hidden;
      height: 100%;
      width: 100%;
    }
  
    .marvel-device > .screen .chat {
      visibility: visible;
    }
  
    .marvel-device {
      visibility: hidden;
    }
  
    .marvel-device .status-bar {
      display: none;
    }
  
    .screen-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  
    .conversation {
      height: calc(100vh - 55px);
    }
    .conversation .conversation-container {
      height: calc(100vh - 120px);
    }
  }

  .bg-warning-light {
    background-color: #ffef90!important;
  }

  .bg-warning-darklight {
    background-color:#fae04f!important;
  }
  .bg-success-light {
    background-color: #D6F4FF!important;
  }
  .bg-danger-light {
    background-color: #FCD1FF!important;
  }
  .bg-primary-light {
    background-color: #A6E7FF!important;
  }